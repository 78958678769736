<template>
    <div>
      <p><u><strong>Last Uploaded Recurring Charges</strong></u> </p>
      <br>
      <div class="result-block-container">

        <div :class="['result-block', executed ? 'show' : '']">
          <button type="button" class="btn btn-success btn-sm" v-b-modal.recurringchargeload-modal>Upload Recurring Charges</button>
          <br><br>
          <!-- <input type="search" v-model="filter"> -->
          <table class="table table-striped w-auto" id="apirecords">
            <thead >
              <tr>
                <th scope="col" @click="sort('AccountNumber')">Account Number</th>
                <th class="th-lg" scope="col" @click="sort('CLI')" >Outbound Ref.</th>
                <th scope="col" @click="sort('ChargeType')">Product</th>
                <th scope="col" @click="sort('Inserted')" >Processed</th>
                <th scope="col" @click="sort('LoadedOn')">Uploaded on</th>
                <th scope="col" >Uploaded by</th>
                <th scope="col" @click="sort('Message')">Message</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(recurringchargeload, index) in SortedapiCDRLoads" :key="index"
               >
                    <td>{{ recurringchargeload.AccountNumber }}</td>
                    <td>{{ recurringchargeload.Outbound }}</td>
                    <td>{{ recurringchargeload.Product }}</td>
                    <td>{{ recurringchargeload.Inserted }}</td>
                    <td>{{ recurringchargeload.LoadedOn }}</td>
                    <td>{{ recurringchargeload.LoadedBy }}</td>
                    <td>{{ recurringchargeload.Message }}</td>
              </tr>
            </tbody>
          </table>
          <div  v-show="showPaging"><p align="center" >
            <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
              <button class="btn btn-outline-primary" @click="nextPage">Next </button>
            </p></div>
        </div>
      </div>

      <!-- ADD modal -->
      <b-modal ref="addrecurringchargeloadModal"
              id="recurringchargeload-modal"
              title="Upload Recurring Charges"
              hide-footer size="m" >
        <b-form @submit="onSubmit" @reset="onReset" class="w-800">
        <b-form-row>
            <b-col>
              <p> Select a comma seperated text file (.txt)</p>

          </b-col>
          </b-form-row>
          <b-form-row>
          <b-col>
              <b-form-file id="form-filename-input"
                            type="file"   size="sm"
                            v-model="addrecurringchargeloadForm.FileName"
                            placeholder="Select a upload file">
              </b-form-file>
          </b-col>
        </b-form-row>

        <b-form-row>
            <b-col>
         <p></p>
          </b-col>
          </b-form-row>
          <div class="container">
          <div class="row">
              <div class="col text-center">
              <b-button-group>
            <b-button type="submit" variant="primary">Submit</b-button>
            <b-button type="reset" variant="danger">Reset</b-button>
            <b-button type="button" variant="info" v-on:click="download()" >Download Template</b-button>
          </b-button-group>
              </div>
          </div>
          </div>
        </b-form>
      </b-modal>

    </div>
  </template>

  <script>
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
        timer: "",
        selectedCarrier:"",
        addrecurringchargeloadForm: {
          Carrier: '' ,
          CusBillingName: '' ,
          FileName: ''

        },
        apiarecurringchargeload: [],
        executed: false,
        message: 'initial alert',
        currentSort:'LoadedOn',
        currentSortDir:'asc',
        pageSize:20,
        showPaging:false,
        currentPage:1,
        filter:'',
      };
    },

    computed:{
    filteredrecurringchargeloads() {
      console.log('filtered recurringchargeload');
        return this.apiarecurringchargeload
      },
    SortedapiCDRLoads:function() {
      console.log('sort recurringchargeload');
      if(Array.isArray(this.apiarecurringchargeload)){
        return this.filteredrecurringchargeloads.slice().sort((a,b) => {
          let modifier = 1;
          if(this.currentSortDir === 'desc') modifier = -1;
          if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
          if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
          return 0;
        }).filter((row, index) => {
          let start = (this.currentPage-1)*this.pageSize;
          let end = this.currentPage*this.pageSize;
          if(index >= start && index < end) return true;
        });
      }
    },
    },
    methods: {
      download: function() {
        console.log("in download")
        var data = "AccountNumber,Outbound,Product,CustomisedName,BillFrequency,Quantity,CostUnitPrice,SellUnitPrice,TermStartDate,RenewalDate,ISA,Notes,FinalBillRun,InsertOrUpdate"
        var fileURL = window.URL.createObjectURL(new Blob([data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', "UploadRecurringCharges.txt");
        document.body.appendChild(fileLink);

        fileLink.click();

      },
      carrierChange: function (e) {
          this.selectedCarrier = e;
      },
      nextPage:function() {
        if((this.currentPage*this.pageSize) < this.filteredrecurringchargeloads.length) this.currentPage++;
      },
      prevPage:function() {
        if(this.currentPage > 1) this.currentPage--;
      },
      sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },


      // *******************************************
      // List recurringchargeloads
      // *******************************************
      async callApi(lablex) {

        console.log(lablex)
        console.log('getting the recurring load data')
        try {
          document.body.style.cursor = 'wait';
          const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/recurringcharges/load`);
          document.body.style.cursor = 'auto';
          this.apiarecurringchargeload = data;
          console.log(data)
        //   if (this.apiarecurringchargeload.length < this.pageSize){
        //     this.showPaging = false
        //   }
          if (this.apiarecurringchargeload.length > this.pageSize){
            this.showPaging = true
          }
          else{
            this.showPaging = false
          }
          this.executed = true;
        } catch (e) {
          this.apiarecurringchargeload = `Error: the server responded with '${e.response}'`;
        }
      },
      // Add recurringchargeload method to post to API
      async addrecurringchargeload(payload) {

        let formData = new FormData();
        console.log( payload.FileName);
        formData.append('Carrier', payload.Carrier);
        formData.append('BillingName', payload.BillingName);
        formData.append('file', payload.FileName);
        try {
          document.body.style.cursor = 'wait';
          const { data } =  await this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/recurringcharges/load`, formData).then(() => {
            this.message = 'arecurringchargeload added!';
            this.showMessage = true;
            console.log('Here we call update and calApi then');
            document.body.style.cursor = 'auto';
            // this.$forceUpdate();
            // this.$router.go()
            console.log("in add load after")
            this.callApi("coming add  AAA");
            // this.callApi();

          }).catch((err) => {
            if(err.response.status == 409) {
              alert("Duplicated recurringchargeload. status code:  " + err.response.status)
              }
            if(err.response.status != 409) {
              alert("Problem with add recurringchargeload. status code:  " + err.response.status)
              }
            console.error(err);
            console.log("in error")
            this.callApi();

          });

          // this.apiarecurringchargeload = data;
          // this.executed = true;
          document.body.style.cursor = 'auto';
          console.log("in add load BBB")
          this.callApi("coming add  BBB");
          console.log(data)
          //this.callApi()
        } catch (e) {
          this.apiarecurringchargeload = `Error: the server responded with '${e.response}'`;
        }
      },
      initForm() {
        console.log('init form');
          this.addrecurringchargeloadForm.Carrier =  '';
          this.addrecurringchargeloadForm.BillingName =  '';
          this.addrecurringchargeloadForm.FileName =  '';

      },
      // *******************************************
      // Add recurringchargeload on submit
      // *******************************************
      onSubmit(evt) {
        console.log('on submit');
        evt.preventDefault();
        this.$refs.addrecurringchargeloadModal.hide();
        console.log('MaintenaceOnly');

        const payload = {
          FileName: this.addrecurringchargeloadForm.FileName ,
        };
        document.body.style.cursor = 'wait';
        this.addrecurringchargeload(payload);
        //this.initForm();
      },
      onReset(evt) {
        evt.preventDefault();
        this.$refs.addrecurringchargeloadModal.hide();
        this.initForm();
      },
    //
      onResetLoad() {
        console.log('onresetload')
        if(confirm("Are you sure you want to reset all CDR loads?")){
          this.resetLoad();
        }

      },
      cancelAutoUpdate() {
        clearInterval(this.timer);
      },

    },
    created() {
      console.log('created')
      this.callApi();
      this.timer = setInterval(this.callApi, 20000);

    },
    components: {
      alert: Alert,
      listfilter:Filter,
    },
    beforeDestroy() {
      this.cancelAutoUpdate();
    },
  };
  </script>
