<template>

    <div>
      <p ><u><strong>Usage Summary By Account Report</strong></u> </p>
      <br>
      <div class="result-block-container">

        <div :class="['result-block', executed ? 'show' : '']">
          <div>

          
          <div style="float: left; width: 50%;">
            <b-form @submit="onSubmit" >
            <b-form-group
            id="fieldset-horizontal"
            label-cols-sm="2"
            label-cols-lg="3"
            content-cols-sm
            content-cols-lg="8"
            label="Carrier"
            label-for="input-horizontal"
            >
            <div>
                        <CarrierNameDropDownSummaryVue @carrier-changed="carrierChange"></CarrierNameDropDownSummaryVue>
                    </div>
            </b-form-group>
                <table>
                      <tr>
                        <td><b-button type="submit" variant="primary">Find</b-button></td>
                        <td></td>
                        <td><button type="button" class="btn btn-primary btn-sm" v-on:click="addreportlog">Generate All</button></td>
                    </tr>
                    </table>
          </b-form>

          </div>
          <div style="float: left; width: 50%;">
            <table class="table table-striped w-auto" id="apirecords">
          <thead >
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Started On</th>
              <th scope="col" >Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(report, index) in apiReports" :key="index">
               <td >{{ report.Name }}</td> 
               <td >{{ report.StartedOn }}</td> 
               <td >{{ report.Message }}</td> 
              <td v-if="report.Finished=='Yes'"><button v-on:click="onDownloadReport(report.Name)">Download</button></td>
              <td v-else></td>
            </tr>
          </tbody>
        </table>
          </div>
          <br><br>
        </div>


         <br><br>
         <br><br>
         
         <table v-show="showresult" >
            <tr v-if="apicarrier.length > 0">
                <td><button type="button" class="btn btn-success btn-sm" v-on:click="download">Dowload Results</button></td>
            </tr>
        </table>
        <br><br>
          <table class="table table-striped w-auto" v-show="showresult" >
            <thead>
                <tr>
                <th scope="col" @click="sort('GroupNumber')">Group Number</th>
                <th scope="col" @click="sort('GroupName')">Group Name</th>
                <th scope="col" @click="sort('AccountNumber')">Account Number</th>
                <th scope="col" @click="sort('AccountName')">Account Name</th>
                <!-- <th scope="col" @click="sort('CallCount')">Count</th> -->
                <th scope="col" @click="sort('Quantity')">Quantity</th>
                <th scope="col" @click="sort('Duration')">Duration (m)</th>
                <th scope="col" @click="sort('Data_mb')">Data (MB)</th>                
                <th scope="col" @click="sort('Price')">Price (£)</th>
                <th scope="col" @click="sort('Cost')">Cost (£)</th>
                <th  v-show="showcarrier" scope="col" @click="sort('Cost')">Carrier</th>
                <th v-show="showNetXcarrier"  scope="col" @click="sort('Cost')">NetX Carrier</th> 
                <!-- <Div  v-show="showcarrier">
                  <th scope="col" @click="sort('Cost')">Carrier</th>
                </Div> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="(carrierummary, index) in apicarrier" :key="index">
                <td >{{ carrierummary.GroupNumber }}</td>
                <td >{{ carrierummary.GroupName }}</td>
                <td >{{ carrierummary.AccountNumber }}</td>
                <td >{{ carrierummary.AccountName }}</td>
                <!-- <td align="center">{{ carrierummary.CallCount }}</td> -->
                <td align="center">{{ carrierummary.Quantity }}</td>
                <td align="center">{{ carrierummary.Duration }}</td>
                <td align="center">{{ carrierummary.Data_mb }}</td>
                <td align="center">{{ carrierummary.Price }}</td>
                <td align="center">{{ carrierummary.Cost }}</td>
                <td v-show="showcarrier" align="center">{{ carrierummary.Carrier }}</td>
                <td v-show="showNetXcarrier" align="center">{{ carrierummary.NetXCarrier }}</td>
              </tr>
            </tbody>
          </table>
          <!-- <div v-if="apicarrier.length > 0"><p align="center">
            <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
              <button class="btn btn-outline-primary" @click="nextPage">Next </button>
            </p></div> -->
        </div>
      </div>

    </div>
  </template>

  <script>
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';
  import * as XLSX from 'xlsx'
  import CarrierNameDropDownVue from "../components/CarrierNameDropDown.vue";
  import CarrierNameDropDownSummaryVue from "../components/CarrierNameDropDownSummary.vue";
  // import XLSX from 'xlsx-style'

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
        apicarrier: [],
        executed: false,
        message: 'initial alert',
        currentSort:'AccountName',
        currentSortDir:'asc',
        pageSize:20,
        currentPage:1,
        filter:'',
        showresult: false,
        showdownload:false,
        Account: null,
        showcarrier : true,
        showNetXcarrier : false,
        timer1: "",
        apiReports: [],
      };
    },

    computed:{
    filteredPricedCalls() {
      console.log('filtered suspendedcall');
        return this.apiPricedCalls
      },
    SortedapiPricedCalls:function() {
      console.log('sort suspendedcall');
      return this.filteredPricedCalls.slice().sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
        if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
        return 0;
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
    },
    },
    methods: {
    // on download method
    onDownloadReport(reportname) {
      console.log(reportname)
      this.downloadReport(reportname);
    },
    // api get download report method
    async downloadReport(reportname) {

      console.log('***********************download*****************************')

      try {
        document.body.style.cursor = "wait";
        const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/reports/download/` + reportname, {responseType: 'blob'});
        document.body.style.cursor = 'auto';

        this.executed = true;
        var fileURL = window.URL.createObjectURL(new Blob([data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', reportname);
        document.body.appendChild(fileLink);
        fileLink.click();

      } catch (e) {
        this.apiReports = `Error: the server responded with '${e.response}'`;
      }
    },
    onChangeCallType(event) {
            this.SelectedCallType= event.target.value
            },

    onChangeDirection(event) {
        this.SelectedDirection= event.target.value
        },
    accountChange: function (e) {
        this.apiPricedCalls = null;
        this.showresult = false;
        console.log('£££££££££££ in change account £££££££')
        console.log(this.showresult)
        this.selectedAccount = e;
        },


    carrierChange: function (e) {
      this.selectedCarrier = e;
      this.showNetXcarrier = false;
      this.showresult = false;
      if (this.selectedCarrier == 'ALL') {
        this.showcarrier = false;
      }
      if (this.selectedCarrier == 'NETX2') {
        this.showNetXcarrier = true;
      }
      },
        
    // onChangeReportType(event) {
    //         this.filter = '';
    //         this.SelectedReportType= event.target.value;
    //         },
    
       // *******************************************
    // List report logs
    // *******************************************
    async callLogApi() {

    // console.log(lablex)
    console.log('***********************getting the data')

    try {
      const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/reports/reportlogs/usagebyaccount`);

      this.apiReports = data;
      console.log(data);
      console.log("report returned................");
      this.executed = true;
      if (this.apiReports.length > this.pageSize){
        this.showPaging = true
      }
      else{
        this.showPaging = false
      }
    } catch (e) {
      this.apiReports = `Error: the server responded with '${e.response}'`;
    }
    },

    onSubmit(evt) {
        console.log('on submit');
        this.apiPricedCalls = null;
        this.showresult = false;
        if (!this.selectedCarrier){ // is null or zero
            alert("Please select an option");
            evt.preventDefault();
                    return false;
        }
        this.callApi(this.selectedCarrier);
        // this.showresult = true;
        evt.preventDefault();
        },
      

    download : function() {
      console.log('in download results selected carrier')
      console.log(this.selectedCarrier)
      // customised headers
      var Heading = [[]]
      Heading =  [['Group Number','Group Name','Account Number','Account Name', 'Quantity', 'Duration (m)', 'Data (MB)', 'Price (£)', 'Cost (£)', 'Carrier']];

      // original object headers
      var objHeader = []
      objHeader =['GroupNumber','GroupName','AccountNumber', 'AccountName', 'Quantity', 'Duration', 'Data_mb','Price', 'Cost', 'Carrier'];

      //netx2 headers
      if (this.selectedCarrier == 'NETX2') {
        Heading = [['Group Number','Group Name','Account Number','Account Name', 'Quantity', 'Duration (m)', 'Data (MB)','Price (£)', 'Cost (£)', 'Carrier', 'NetX Carrier']];
        objHeader=  ['GroupNumber','GroupName','AccountNumber', 'AccountName', 'Quantity', 'Duration', 'Data_mb','Price', 'Cost', 'Carrier', 'NetXCarrier'];
      }
      // summary header
      if (this.selectedCarrier == 'ALL') {
        Heading = [
          ['Group Number','Group Name','Account Number','Account Name', 'Quantity', 'Duration (m)', 'Data (MB)', 'Price (£)', 'Cost (£)']
        ];

        // original object headers
        objHeader = ['GroupNumber','GroupName', 'AccountNumber','AccountName', 'Quantity', 'Duration', 'Data_mb', 'Price', 'Cost'];

      }
      console.log('in download results selected carrier XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX')
      console.log(this.selectedCarrier)
      
   
      // set column widths
      // const colWidths = [
      //         { wch: 80 },
      //                   ]
  

        // build based on original object fields but skip the header row
        const data = XLSX.utils.json_to_sheet(this.apicarrier, { origin: 'A2', header: objHeader, skipHeader: true,},)
        
        // Assign widths to columns
        // data['!cols'] = colWidths
     
        // add the customised header
        XLSX.utils.sheet_add_aoa(data, Heading, { origin: 'A1' });

        // Define cell styles (including font size)
        // const cellStyles = {
        //   font: {
        //     sz: 14, // Set font size (e.g., 12)
        //     bold: true, // Set bold font (optional)
        //   },
        //   alignment: {
        //     vertical: 'center', // Vertical alignment (optional)
        //     horizontal: 'center', // Horizontal alignment (optional)
        //   },
        // };

        // // Apply styles to all cells
        // Object.keys(data).forEach((key) => {
        //   if (!key.startsWith('!')) {
        //     // data[key].s = cellStyles; // Apply the style to each cell
        //     key.s = { fill: { fgColor: { rgb: "7A7A7A" } }, font: { color: { rgb: "FFFFFF" } } }
        //   }
        // });


        const wb = XLSX.utils.book_new()

        XLSX.utils.book_append_sheet(wb, data, 'data')
        var moment = require('moment');
        var datestr = moment(new Date()).format('DD-MM-YYYY');
        var filename;
        if (this.selectedCarrier == 'ALL') {
          filename = 'Usage  Summary By Account - ' + datestr + '.xlsx';
        }
        else {
           filename = 'Usage  Summary By Account ('+ this.apicarrier[0]['Carrier']  + ') - ' + datestr + '.xlsx';
        }
      
        XLSX.writeFile(wb,filename)
        },

    nextPage:function() {
        if((this.currentPage*this.pageSize) < this.filteredPricedCalls.length) this.currentPage++;
      },

    prevPage:function() {
        if(this.currentPage > 1) this.currentPage--;
      },

    sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },

     // api get download
      async downloadAll() {

        console.log('***********************download*****************************')
        // var url;
        try {

        //   document.body.style.cursor = "wait";
        //   const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/reports/usagesummarybyaccount/carrier/DOWNLOAD`,{ // Note the change here
        //   responseType: 'blob',
        // });
        //   var moment = require('moment');
        //   var datestr = moment(new Date()).format('DD-MM-YYYY');
        //   document.body.style.cursor = 'auto';
        //   console.log(' in download all after get')
        //   this.executed = true;
        //   var fileURL = window.URL.createObjectURL(new Blob([data]));
        //   var fileLink = document.createElement('a');
        //   fileLink.href = fileURL;
        //   fileLink.setAttribute('download', "UsageSummaryByAccount(All Carriers)-" + datestr + ".xlsx");
        //   document.body.appendChild(fileLink);

        //   fileLink.click();
          var url;
          document.body.style.cursor = "wait";
          url = process.env.VUE_APP_API_BASE_URL + '/api/v1/reports/usagesummarybyaccount/carrier/DOWNLOAD'  ;
          const { data } = await this.$http.get(url);
          console.log(data);
          document.body.style.cursor = 'auto';

        } catch (e) {
          this.apiInvoices = `Error: the server responded with '${e.response}'`;
        }
      },

    // *******************************************
    // List details
    // *******************************************
    async callApi(selectedcarrier) {

        console.log('getting the data')
        // build the filters for the url
        var url;

        document.body.style.cursor = 'wait';

        try {
          // if (carrier == null){
          url = process.env.VUE_APP_API_BASE_URL + '/api/v1/reports/usagesummarybyaccount/carrier/' + selectedcarrier ;
          // }
          // else{
          //   url = process.env.VUE_APP_API_BASE_URL + '/api/v1/reports/carrierdetails/carrier/' + carrier ;
          // }

          console.log(url)
          const { data } = await this.$http.get(url);
          console.log(data);
          this.apicarrier = data;
          this.showresult = true;
          this.showcarrier = true;
          if (this.selectedCarrier == 'ALL') {
            this.showcarrier = false;
          }
          this.executed = true;
        } catch (e) {
          this.apicarrier = `Error: the server responded with '${e.response}'`;
        }
        document.body.style.cursor = 'auto';
      },

    // Add reportlog method to post to API
    async addreportlog(){
      document.body.style.cursor = 'wait';

      try {
        const { data } =  this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/reports/generate/usagebyaccount`, null).then(() => {
          this.message = 'reports started!';
          this.showMessage = true;
          document.body.style.cursor = 'auto';
          this.callLogApi();

        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated reportlog. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add reportlog. status code:  " + err.response.status)
            }
          console.error(err);
          this.callLogApi();
          

        });

        console.log(data)
        //this.callApi()
      } catch (e) {
        this.apiReports = `Error: the server responded with '${e.response}'`;
      }
      },
      cancelAutoUpdate() {
        console.log("report clear interval @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@")
        clearInterval(this.timer1);
      },
      },

    created() {
      console.log('created')
      this.executed = true;
      this.timer1 = setInterval(this.callLogApi, 20000);
      this.callLogApi();

    },
    components: {
      alert: Alert,
      CarrierNameDropDownVue: CarrierNameDropDownVue,
      CarrierNameDropDownSummaryVue:CarrierNameDropDownSummaryVue,
      listfilter:Filter
    },
  };
  </script>
