<template>
  <div>
    <p ><u><strong>Itemised Usage Report</strong></u> </p>
      <br>
      <div class="result-block-container">

      <b-form @submit="onSubmit" >

<b-form-group
    id="fieldset-horizontal"
    label-cols-sm="2"
    label-cols-lg="2"
    content-cols-sm
    content-cols-lg="3"
    label="Carrier"
    label-for="input-horizontal"
    >
    <div>
                <CarrierNameDropDownVue @carrier-changed="carrierChange"></CarrierNameDropDownVue>
    </div>
    </b-form-group>
<b-form-group
  id="fieldset-horizontal"
  label-cols-sm="2"
  label-cols-lg="2"
  content-cols-sm
  content-cols-lg="3"
  label="Account"
  label-for="input-horizontal"
>
<div >
    <CallAccountDropDownVue required @account-changed="accountChange"></CallAccountDropDownVue>
        </div>
</b-form-group>
<b-form-group
    id="fieldset-horizontal"
    label-cols-sm="1"
    label-cols-lg="2"
    content-cols-sm
    content-cols-lg="3"
    label="Destination starts with"
    label-for="input-horizontal"
    >
    <b-form-input id="form-fromdate-input"
              type="text"   size="sm"
              v-model="DilalledNumber"
              placeholder=""
               >
    </b-form-input>
    </b-form-group>
<b-button type="submit" variant="primary">Generate</b-button>
</b-form>

 <br><br>

        <table class="table table-striped w-auto" id="apirecords">
          <thead >
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Started On</th>
              <th scope="col" >Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(report, index) in SortedapiBillLogs" :key="index">
               <td >{{ report.Name }}</td> 
               <td >{{ report.StartedOn }}</td> 
               <td >{{ report.Message }}</td> 
              <td v-if="report.Finished=='Yes'"><button v-on:click="onDownloadReport(report.Name)">Download</button></td>
              <td v-else></td>
            </tr>
          </tbody>
        </table>
        <div v-show="showPaging"><p align="center">
          <b-pagination
            v-model="currentPage"
            align="center"
            :total-rows="filteredbilllogs.length"
            :per-page="pageSize"
            aria-controls="apirecords"
            ></b-pagination>
        <!-- <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
          <button class="btn btn-outline-primary" @click="nextPage">Next </button> -->
        </p>
    </div>
      </div>
    </div>
<!-- 
  </div> -->
</template>

<script>
import Alert from '../components/Alert.vue';
import Filter from '../components/Filter.vue';
import CallAccountDropDownVue from "../components/CallAccountDropDown.vue";
// import * as XLSX from 'xlsx'
import CarrierNameDropDownVue from "../components/CarrierNameDropDown.vue";

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
Name: "Api",
data() {
  return {
    isAdmin: isAdmin(),
    editButtonText: editButtonText(),
      timer1: "",
      timer2: "",
      timer3: "",
      selectedCarrier:"",
      addbilllogForm: {
        Carrier: '' ,
        CusBillingName: '' ,
        FileName: ''

      },
      DilalledNumber: null,
      apiReports: [],
      apiReportLog:{},
      executed: false,
      message: 'initial alert',
      currentSort:'CreatedOn',
      currentSortDir:'asc',
      pageSize:20,
      showPaging:false,
      currentPage:1,
      apifinalpost:null,
      filter:'',
    };
  },

  computed:{
  filteredbilllogs() {
    console.log('filtered billlog');
      return this.apiReports.filter(c => {
        if(this.filter == '') return true;
      if (c.AccountNumber == null) c.AccountNumber = '';
      if (c.GroupNo == null) c.GroupNo = '';
      if (c.AccountName == null) c.AccountName = '';
      if (c.GroupName == null) c.GroupName = '';
      if (c.SageAccount == null) c.SageAccount = '';
      if (c.BillNumber == null) c.BillNumber = '';
      return c.AccountNumber.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
            c.GroupNo.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
            c.AccountName.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
            c.GroupName.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
            c.SageAccount.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
            c.BillNumber.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
    })
    },
  SortedapiBillLogs:function() {
    console.log('sort billlog');
    return this.filteredbilllogs.slice().sort((a,b) => {
      let modifier = 1;
      if(this.currentSortDir === 'desc') modifier = -1;
      if (a[this.currentSort] === null) return 1;
      if (b[this.currentSort] === null) return -1;
      if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
      if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
      return 0;
    }).filter((row, index) => {
      let start = (this.currentPage-1)*this.pageSize;
      let end = this.currentPage*this.pageSize;
      if(index >= start && index < end) return true;
    });
  },
  },
  methods: {

    // on download method
    onDownloadReport(reportname) {
    console.log(reportname)
    this.downloadReport(reportname);
    },
    // api get download report method
    async downloadReport(reportname) {

      console.log('***********************download*****************************')

      try {
        document.body.style.cursor = "wait";
        const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/reports/download/` + reportname, {responseType: 'blob'});
        document.body.style.cursor = 'auto';

        this.executed = true;
        var fileURL = window.URL.createObjectURL(new Blob([data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', reportname);
        document.body.appendChild(fileLink);
        fileLink.click();

      } catch (e) {
        this.apiReports = `Error: the server responded with '${e.response}'`;
      }
    },
    carrierChange: function (e) {
        this.selectedCarrier = e;
    },
    accountChange: function (e) {
        this.selectedAccount = e;
        },
    nextPage:function() {
      if((this.currentPage*this.pageSize) < this.filteredbilllogs.length) this.currentPage++;
    },
    prevPage:function() {
      if(this.currentPage > 1) this.currentPage--;
    },
    sort:function(s) {
    //if s == current sort, reverse
    if(s === this.currentSort) {
      this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
    }
    this.currentSort = s;
    },

    onSubmit(evt) {
      this.apiPricedCalls = [];
      this.showresult = false;
      console.log(this.selectedAccount)
      if (!this.selectedCarrier) { // is null or zero
            if (!this.selectedAccount){ // is null or zero
            alert("Please select report criteria");
            evt.preventDefault();
                    return false;
            }
            // alert("Please select a carrier");
            // evt.preventDefault();
            //         return false;
            }
            // this.callApi( this.selectedCarrier,this.selectedAccount,this.DilalledNumber);
      this.addreportlog( this.selectedCarrier,this.selectedAccount,this.DilalledNumber);
      evt.preventDefault();
    },
    // *******************************************
    // List report logs
    // *******************************************
    async callApi() {

      // console.log(lablex)
      console.log('***********************getting the data')

      try {
        const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/reports/reportlogs/itemisedusage`);

        this.apiReports = data;
        console.log(data);
        console.log("report returned................");
        this.executed = true;
        if (this.apiReports.length > this.pageSize){
          this.showPaging = true
        }
        else{
          this.showPaging = false
        }
      } catch (e) {
        this.apiReports = `Error: the server responded with '${e.response}'`;
      }
    },
    // Add reportlog method to post to API
    async addreportlog( carrier, account, dialled){

      console.log('getting the data')
      // build the filters for the url
        var filters =[];
        var accountfilter = {"name":"AccountId","op":"eq","val":account}
        var carrierfilter = { "name": "Carrier", "op": "eq", "val": carrier };
        var dialledfilter = { "name": "Dialled", "op": "eq", "val": dialled };

          if (typeof(carrier) != "undefined" && carrier != null && carrier != "") {
              filters.push(carrierfilter)
          }
          if (typeof(account) != "undefined" && account != null  && account != "") {
              filters.push(accountfilter)
          }
          if (typeof(dialled) != "undefined" && dialled != null && dialled != "") {
              filters.push(dialledfilter)
          }
          
        let queryfilters = JSON.stringify(filters);
        
        // var url;
        document.body.style.cursor = 'wait';

      try {
        const { data } =  this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/reports/generate/itemisedusage?q={"filters":` + queryfilters + `}`, null).then(() => {
          this.message = 'reports started!';
          this.showMessage = true;
          document.body.style.cursor = 'auto';
          this.callApi();

        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated reportlog. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add reportlog. status code:  " + err.response.status)
            }
          console.error(err);
          this.callApi();
          

        });

        console.log(data)
        //this.callApi()
      } catch (e) {
        this.apiReports = `Error: the server responded with '${e.response}'`;
      }
    },
    initForm() {
      console.log('init form');
        this.addbilllogForm.Carrier =  '';
        this.addbilllogForm.BillingName =  '';
        this.addbilllogForm.FileName =  '';

    },
    // *******************************************
    // Add billlog on submit
    // *******************************************
    // onStartBills() {
    //   console.log('on start reports');
    //   this.addreportlog();
    //   //this.initForm();
    // },
    // onReset(evt) {
    //   evt.preventDefault();
    //   this.$refs.addbilllogModal.hide();
    //   this.initForm();
    // },
    // },
    // reset load
    // *******************************************
    async resetLoad() {

      console.log("in reset load before")
      try {
        const { data } =  this.$http.delete(`${process.env.VUE_APP_API_BASE_URL}/api/v1/pricing/reset`).then(() => {
          this.message = 'account removed!';
          this.showMessage = true;
          console.log("in removeAccount after")
          this.callApi("coming from delete");

        }).catch((err) => {
          console.log("in removeAccount error")
          console.error(err);
          this.callApi();

        });

        console.log('delete data is:')
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiAccounts = `Error: the server responded with '${e.response}'`;
      }
    },
    cancelAutoUpdate() {
      console.log("report clear interval @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@")
      clearInterval(this.timer1);
      clearInterval(this.timer2);
      clearInterval(this.timer3);
    },
  },
  created() {
    console.log('created')
    this.timer1 = setInterval(this.callApi, 20000);
    this.callApi();

  },
  components: {
    alert: Alert,
    listfilter:Filter,
    CallAccountDropDownVue: CallAccountDropDownVue,
    CarrierNameDropDownVue: CarrierNameDropDownVue,
  },
  beforeDestroy() {
  this.cancelAutoUpdate();
},
};
</script>
