<template>

  <div>
    <p v-if="showfilter"><strong>Deleted Recurring Charges</strong> </p>
    <p v-else><strong>Deleted Recurring Charges ({{ accountname }} {{ accountnumber }})</strong> </p>
    <br>
    <div class="result-block-container" width="1200">
      <div :class="['result-block', executed ? 'show' : '']">
        <b-form v-if="showfilter" @submit="onSubmit">
          <b-form-group id="fieldset-horizontal" label-cols-sm="2" label-cols-lg="2" content-cols-sm content-cols-lg="5"
            label="From Date" label-for="input-horizontal">
            <b-form-input id="form-fromdate-input" type="date" size="sm" v-model="FromDate"
              placeholder="Enter from date">
            </b-form-input>
          </b-form-group>
          <b-form-group id="fieldset-horizontal" label-cols-sm="2" label-cols-lg="2" content-cols-sm content-cols-lg="5"
            label="To Date" label-for="input-horizontal">
            <b-form-input id="form-todate-input" type="date" size="sm" v-model="ToDate" placeholder="Enter to date">
            </b-form-input>
          </b-form-group>


          <b-button type="submit" variant="primary">Find</b-button>
        </b-form>
        <table class="table table-striped" id="apirecords">
          <thead>
            <tr>
              <th scope="col">Account No</th>
              <th scope="col">Account Name</th>
              <th scope="col">Cost Centre No</th>
              <th scope="col">Cost Centre Name</th>
              <th class="th-lg" scope="col">Outbound</th>
              <th class="th-lg" scope="col">Label</th>
              <th class="th-lg" scope="col">Charge Number</th>
              <th class="th-lg" scope="col">Product</th>
              <th scope="col">Frequency</th>
              <th scope="col">Quantity</th>
              <th scope="col">Unit price</th>
              <th scope="col">Total Price</th>
              <th class="th-sm" scope="col">Term Start</th>
              <th class="th-sm" scope="col">Renewal Date</th>
              <!-- <th class="th-sm" scope="col" >ISA</th>
                <th class="th-sm" scope="col" >Final Bill Run</th> -->
              <th scope="col">Modified on</th>
              <th scope="col">User</th>
              <th scope="col">Action</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(history, index) in SortedapiRecurringCharge" :key="index">
              <td>{{ history.recurringcharge.account.AccountNumber }}</td>
              <td>{{ history.recurringcharge.account.Name }}</td>
              <td v-if="history.recurringcharge.outbound.costcentre">{{
      history.recurringcharge.outbound.costcentre.CostCentreNumber }}
              </td>
              <td v-else></td>
              <td v-if="history.recurringcharge.outbound.costcentre">{{ history.recurringcharge.outbound.costcentre.Name }}</td>
              <td v-else></td>
              <td v-if="history.recurringcharge.outbound">{{ history.recurringcharge.outbound.PhoneNumber }}</td>
              <td v-else></td>
              <td v-if="history.recurringcharge.outbound">{{ history.recurringcharge.outbound.Label }}</td>
              <td v-else></td>
              <td>{{ history.recurringcharge.ChargeNumber }}</td>

              <div v-if="history.recurringcharge.product">
                <td>{{ history.recurringcharge.product.Name }}</td>
              </div>
              <div v-else>
                <td></td>
              </div>

              <td>{{ history.recurringcharge.BillFrequency }}</td>
              <td>{{ history.recurringcharge.Quantity }}</td>
              <td>{{ history.recurringcharge.SellUnitPrice}}</td>
              <td>{{ history.recurringcharge.SellTotalPrice }}</td>
              <td>{{ history.recurringcharge.TermStartDate }}</td>
              <td>{{ history.recurringcharge.RenewalDate }}</td>
              <!-- <td>{{ history.recurringcharge.ISA }}</td>
                <td>{{ history.recurringcharge.LastBillRun }}</td> -->
              <td>{{ history.modifiedon }}</td>
              <td>{{ history.modifiedby }}</td>
              <td>{{ history.method }}</td>
              <td align="center"><a
                  :href="'/recurringchargehistory?recurringcharge_id=' + history.recurringcharge.RCId + '&chargenumber=' + history.recurringcharge.ChargeNumber"
                  target="_blank">History</a></td>
              <td>
                <div class="btn-group" role="group">
                  <button type="button" class="btn btn-warning btn-sm" label="view"
                    v-b-modal.recurringcharge-update-modal @click="editRecurringCharge(history)">
                    View
                    <!-- {{editButtonText}} -->
                  </button>

                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-show="showPaging">
          <p align="center">
            <b-pagination v-model="currentPage" align="center" :total-rows="filteredRecurringCharge.length"
              :per-page="pageSize" aria-controls="apirecords"></b-pagination>
            <!-- <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
              <button class="btn btn-outline-primary" @click="nextPage">Next </button> -->
          </p>
        </div>
      </div>
    </div>

    <!-- EDIT modal -->
    <b-modal ref="editRecurringChargeModal" id="recurringcharge-update-modal" title="Recurring charge history"
      hide-footer size="m">
      <b-form @reset="onResetUpdate" class="w-600">
        <b-form-row>
          <b-col>
            <b-form-group id="form-account-group" label="Charge Number" label-for="form-customerno-input" label-cols="2"
              label-cols-lg="3" label-size="sm" size="sm">
              <b-form-input id="form-Quantity-input" type="text" size="sm" readonly
                v-model="editRecurringChargeForm.ChargeNumber">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-account-group" label="Outbound" label-for="form-customerno-input" label-cols="2"
              label-cols-lg="3" label-size="sm" size="sm">
              <b-form-input id="form-Quantity-input" type="text" size="sm" readonly
                v-model="editRecurringChargeForm.outbound.PhoneNumber">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-account-group" label="Product" label-for="form-customerno-input" label-cols="2"
              label-cols-lg="3" label-size="sm" size="sm">
              <b-form-input id="form-Quantity-input" type="text" size="sm" readonly v-model="productname">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-CustomName-group" label="Customised Name" label-for="form-CustomName-input"
              label-cols="2" label-cols-lg="3" label-size="sm" size="sm">
              <b-form-input id="form-Name-input" type="text" size="sm" readonly
                v-model="editRecurringChargeForm.CustomName" placeholder="Enter Customised Name">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-UseCustomName-group" label="Use Customised Name" label-for="form-UseCustomName-input"
              label-cols="4" label-cols-lg="4" label-size="sm" size="sm">
              <b-form-checkbox id="UseCustomName" disabled='true' v-model="editRecurringChargeForm.UseCustomName"
                name="UseCustomName">
              </b-form-checkbox>
            </b-form-group>

          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-BillFrequency-group" label="Bill Frequency" label-for="form-BillFrequency-input"
              label-cols="4" label-cols-lg="3" label-size="sm" size="xs">
              <div class="dropdown" align="left">
                <select disabled='true' v-model="editRecurringChargeForm.BillFrequency"
                  @change="onChangeBillFrequency($event)" required>
                  <option value="">Select bill frequency</option>
                  <option v-for="(type, index) in billfrequencies" :value="type.id" :key="index">
                    {{ type.value }}
                  </option>
                </select>
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-Quantity-group" label="Quantity" label-for="form-Quantity-input" label-cols="2"
              label-cols-lg="3" label-size="sm" size="sm">
              <b-form-input id="form-Quantity-input" type="text" size="sm" readonly
                v-model="editRecurringChargeForm.Quantity" placeholder="Enter quantity">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-CostUnitPrice-group" label="Cost Unit Price" label-for="form-CostUnitPrice-input"
              label-cols="2" label-cols-lg="6" label-size="sm" size="sm">
              <b-form-input id="form-CostUnitPrice-input" type="text" size="sm" readonly
                v-model="editRecurringChargeForm.CostUnitPrice" placeholder="Enter cost unit price">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-CostTotalPrice-group" label="Cost Total Price" label-for="form-CostTotalPrice-input"
              label-cols="2" label-cols-lg="6" label-size="sm" size="sm">
              <b-form-input readonly id="form-CostTotalPrice-input" type="text" size="sm"
                v-model="editRecurringChargeForm.CostTotalPrice" placeholder="Enter cost unit price">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-SellUnitPrice-group" label="Sell Unit Price" label-for="form-SellUnitPrice-input"
              label-cols="2" label-cols-lg="6" label-size="sm" size="sm">
              <b-form-input id="form-SellUnitPrice-input" type="text" size="sm" readonly
                v-model="editRecurringChargeForm.SellUnitPrice" placeholder="Enter sell unit price">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-SellTotalPrice-group" label="Sell Total Price" label-for="form-SellTotalPrice-input"
              label-cols="2" label-cols-lg="6" label-size="sm" size="sm">
              <b-form-input readonly id="form-SellTotalPrice-input" type="text" size="sm"
                v-model="editRecurringChargeForm.SellTotalPrice" placeholder="Enter sell unit price">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <b-form-group id="form-TermStartDate-group" label="Term Start Date" label-for="form-TermStartDate-input"
              label-cols="2" label-cols-lg="6" label-size="sm" size="sm">
              <b-form-input id="form-TermStartDate-input" type="text" size="sm"
                v-model="editRecurringChargeForm.TermStartDate" readonly
                placeholder="Enter term star in this format dd/mm/yyyy">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-RenewalDate-group" label="Term End Date" label-for="form-RenewalDate-input"
              label-cols="2" label-cols-lg="6" label-size="sm" size="sm">
              <b-form-input id="form-RenewalDate-input" type="text" size="sm" readonly
                v-model="editRecurringChargeForm.RenewalDate" placeholder="Enter term end in this format dd/mm/yyyy">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-ISA-group" label="ISA" label-for="form-ISA-input" label-cols="2" label-cols-lg="3"
              label-size="sm" size="sm">
              <b-form-input id="form-Name-input" type="text" size="sm" readonly v-model="editRecurringChargeForm.ISA"
                placeholder="">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-Notes-group" label="Notes" label-for="form-Notes-input" label-cols="2"
              label-cols-lg="3" label-size="sm" size="sm">
              <b-form-input id="form-Name-input" type="text" size="sm" readonly v-model="editRecurringChargeForm.Notes"
                placeholder="">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-AutoDelete-group" label="Final Bill Run" label-for="form-AutoDelete-input"
              label-cols="1" label-cols-lg="6" label-size="sm" size="sm">
              <b-form-input id="form-AutoDelete-input" type="text" size="sm" readonly
                v-model="editRecurringChargeForm.LastBillRun" placeholder="">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-AutoDeletel-group" label="" label-for="form-AutoDeletel-input" label-cols="1"
              label-cols-lg="6" label-size="sm" size="sm">
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-ModifiedOn-group" label="Modified on" label-for="form-ModifiedOn-input"
              label-cols="2" label-cols-lg="3" label-size="sm" size="xs">
              <b-form-input readonly id="form-ModifiedOn-input" type="text" size="sm"
                v-model="editRecurringChargeForm.modifiedon">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-ModifiedBy-group" label="User" label-for="form-ModifiedBy-input" label-cols="2"
              label-cols-lg="3" label-size="sm" size="xs">
              <b-form-input readonly id="form-ModifiedBy-input" type="text" size="sm"
                v-model="editRecurringChargeForm.modifiedby">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-ModifiedBy-group" label="Action" label-for="form-ModifiedBy-input" label-cols="2"
              label-cols-lg="3" label-size="sm" size="xs">
              <b-form-input readonly id="form-ModifiedBy-input" type="text" size="sm"
                v-model="editRecurringChargeForm.action">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <p></p>
          </b-col>
        </b-form-row>
        <div class="container">
          <div class="row" v-if="isAdmin">
            <div class="col text-center">
              <b-button-group>
                <b-button type="reset" variant="danger">Close</b-button>
              </b-button-group>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

  <script>
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';
  import ChargeCategoryDropDown from "../components/ChargeCategoryDropDown.vue";
  import AccountDropdownVue from "../components/AccountDropdown.vue";
  import ServiceTypeDropDown from  "../components/ServiceTypeDropDown.vue";
  // import AccountCLIDropdownVue from "../components/AccountCLIDropDown.vue";

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
      FromDate : null,
        ToDate : null,
        billfrequencies: [{ "id":"M", "value":'Monthly      '}, {"id":"Q","value":'Quartly'}, {"id":"A","value":'Annually'}],
        accountoutbounds : null,
        SelectedBillFrequency: '',
        selectedChargeCategory:"",
        SelectedCLI : "",
        editRecurringChargeForm: {
            RCId  : null,
            ServiceTypeId : null,
            AccountId : null,
            OutboundId : null,
            CustomName : null,
            UseCustomName : null,
            Notes : null,
            BillFrequency: null,
            Quantity : null,
            SellUnitPrice : null,
            SellTotalPrice : null,
            CostUnitPrice : null,
            CostTotalPrice : null,
            TermStartDate : null,
            RenewalDate : null,
            LastBillDate: null,
            NextBillDate: null,
            CreatedOn : null,
            ModifiedOn : null,
            ModifiedBy : null,
            LastBillRun: null,
            ISA : null,
            account:{},
            outbound:{},
            servicetype:{},
            product:{},
            modifiedby: null,
            modifiedon: null,
            action: null
          },
        apiRecurringCharge: [],
        apiHistoryRecurring:null,
        executed: false,
        message: 'initial alert',
        currentSort:'modifiedon',
        currentSortDir:'asc',
        pageSize:20,
        showPaging:false,
        currentPage:1,
        filter:'',
        ShowAccount:true,
        selected_account: null,
        productname: null,
        showfilter : true,
        account_id : null,
        accountname:null,
        accountnumber:null
      };
    },

    computed:{
    filteredRecurringCharge() {
      return this.apiRecurringCharge
      },

    SortedapiRecurringCharge:function() {
      if (this.apiRecurringCharge != null){
      return this.filteredRecurringCharge.slice().sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(this.currentSort === 'account.Name'){
          var prop = this.currentSort.split('.');
          var len = prop.length;
            var i = 0;
            while( i < len ) { a = a[prop[i]]; b = b[prop[i]]; i++; }
            if (a < b) {
                return -1 * modifier;
            } else if (a > b) {
                return 1 * modifier;
            } else {
                return 0;
            }
        }
        else{
          if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
          if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
          return 0;
        }
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
    }
        else{ return null}
  },

    },
    methods: {
      onChangeBillFrequency(event) {
      this.SelectedBillFrequency= event.target.value
      },
      onChangeCLI(event) {
      this.SelectedCLI= event.target.value
      },
      servicetypeChange: function (e) {
        this.selectedServiceType = e;
        if(e == ''){
            this.selectedServiceType = null;
        }
       },
       accountChange: function (e) {
        // this.accountoutbounds = [{ "id":"M", "value":'Monthly      '}, {"id":"Q","value":'Quartly'}, {"id":"A","value":'Annually'}],
        // this.getAccountCLI(),
        this.selectedAccount= e;
        if(e == ''){
            this.selectedAccount = null;
        }
       },
       onSubmit(evt) {
            console.log('on submit');
            this.apiHistories = null;
            this.showresult = false;
            var todate = null

            if (this.ToDate != null){
            todate = this.ToDate + ' 23:59:59';
            }

            if (this.ToDate != null & this.FromDate > this.ToDate){
                evt.preventDefault();
                alert("To date can not be before the from date")
            }
            else{
                this.callApi( this.FromDate, todate);
                evt.preventDefault();
            }  
        },
      nextPage:function() {
        if((this.currentPage*this.pageSize) < this.filteredRecurringCharge.length) this.currentPage++;
      },
      prevPage:function() {
        if(this.currentPage > 1) this.currentPage--;
      },
      sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },
      // *******************************************
      // get Product details
      // *******************************************
      async getRecurringApi(productid) {
  
        console.log(productid)
        console.log('getting the account data')
        try {
        //   document.body.style.cursor = "wait";
          var url;
          url =  process.env.VUE_APP_API_BASE_URL + '/api/v1/recurringcharges/' + productid
          const { data } = await this.$http.get(url);
        //   document.body.style.cursor = 'auto';
          this.apiHistoryRecurring = data;
          console.log('got the cli data from api')
          console.log("GET Product DETAILS")
          console.log(data)
          this.executed = true;
        } catch (e) {
          this.apiAccounts = `Error: the server responded with '${e.response}'`;
        }
      },

      // *******************************************
      // List recurringcharge history
      // *******************************************
      async callApi(fromdate, todate) {

        console.log('getting the data')
        // build the filters for the url
        var filters =[];
        var fromfilter = {"name":"modifiedon","op":">=","val":fromdate}
        var tofilter = {"name":"modifiedon","op":"<","val":todate}

        if (typeof(fromdate) != "undefined" && fromdate != null && fromdate != "") {
            filters.push(fromfilter)
        }
        if (typeof(todate) != "undefined" && todate != null && todate != "") {
            filters.push(tofilter)
        }
        let queryfilters = JSON.stringify(filters);

        this.showPaging = false

        try {
          document.body.style.cursor = 'wait';
          var url;
          url =  process.env.VUE_APP_API_BASE_URL + '/api/v1/histories/entitytype/recurringcharge/deleted?q={"filters":' + queryfilters + '}' ;
          console.log(url)
          const { data } = await this.$http.get(url);
          this.apiRecurringCharge = data;
          document.body.style.cursor = 'auto';
          console.log(data)
        //   this.getRecurringApi(this.$route.query.recurringcharge_id)
          if (this.apiRecurringCharge.length > this.pageSize){
            this.showPaging = true
          }
          this.executed = true;
        } catch (e) {
          this.apiRecurringCharge = `Error: the server responded with '${e.response}'`;
        }
      },

       // *******************************************
      // List recurringcharge history
      // *******************************************
      async callAccountDeletedApi() {
          this.showPaging = false
          try {
            document.body.style.cursor = 'wait';
            var url;
            url =  process.env.VUE_APP_API_BASE_URL + '/api/v1/histories/entitytype/recurringcharge/account/' + this.account_id ;
            console.log(url)
            const { data } = await this.$http.get(url);
            this.apiRecurringCharge = data;
            document.body.style.cursor = 'auto';
            console.log(data)
          //   this.getRecurringApi(this.$route.query.recurringcharge_id)
            if (this.apiRecurringCharge.length > this.pageSize){
              this.showPaging = true
            }
            this.executed = true;
          } catch (e) {
            this.apiRecurringCharge = `Error: the server responded with '${e.response}'`;
          }
          },
      initForm() {
        console.log('init form');
          this.addRecurringChargeForm.CustomName =  null;
          this.addRecurringChargeForm.Notes = null;
          this.addRecurringChargeForm.BillFrequency =  null;
          this.addRecurringChargeForm.Quantity = null;
          this.addRecurringChargeForm.SellTotalPrice =  null;
          this.addRecurringChargeForm.CostUnitPrice = null;
          this.addRecurringChargeForm.TermStartDate =  null;
          this.addRecurringChargeForm.RenewalDate = null;
      },


      onReset(evt) {
        evt.preventDefault();
        this.$refs.addRecurringChargeModal.hide();
        this.initForm();
      },
    //
      editRecurringCharge(history) {
        console.log('editRecurringCharge');
        console.log(history.recurringcharge);
        console.log("history product.................")
        console.log(history.recurringcharge.product);
        if (typeof(history.recurringcharge.product) != "undefined"){
          this.productname = history.recurringcharge.product.Name;
        }
        else{
          this.productname = "";
        }
        this.selectedAccount = history.recurringcharge.AccountId
        // this.getAccountCLI()
        this.SelectedCLI = history.recurringcharge.OutboundId
        this.selectedServiceType = history.recurringcharge.ServiceTypeId

        this.editRecurringChargeForm = history.recurringcharge;
        this.editRecurringChargeForm.modifiedby = history.modifiedby
        this.editRecurringChargeForm.modifiedon = history.modifiedon
        this.editRecurringChargeForm.action = history.method

        this.selectedChargeband = history.recurringcharge.CBId
        this.SelectedBillFrequency = history.recurringcharge.BillFrequency
        // this.callApi()
      },


      onResetUpdate(evt) {
        evt.preventDefault();
        this.$refs.editRecurringChargeModal.hide();
        this.initForm();
        this.getRecurringCharge(); // why?
      },
    },
    created() {
      console.log('created')
      if (typeof(this.$route.query.account_id) != "undefined"){
        this.account_id = this.$route.query.account_id;
        this.accountname = this.$route.query.accountname;
        this.accountnumber = this.$route.query.accountnumber;
        this.showfilter = false;
        this.callAccountDeletedApi()
      }
      this.executed = true;
    //   this.callChargebandApi();
    //   this.callApi();

    },
    components: {
      alert: Alert,
      listfilter:Filter,
      AccountDropdownVue: AccountDropdownVue,
      ServiceTypeDropDown: ServiceTypeDropDown,
      ChargeCategoryDropDown: ChargeCategoryDropDown,
      // AccountCLIDropdownVue: AccountCLIDropdownVue,
    },
  };
  </script>
